@media (min-width: 1900px) {
  .why-us {
    padding-top: 10%;
  }


  .us-roles-container {
    .us-card-container {
      .us-card-container-row {
        .us-title {
          margin-top: 8vh;
          width: 50%;
        }

        .us-description {
          width: 70%;
        }
      }
    }
  }

  .carousel-container {
    padding-top: 3rem;
  }

}

@media (max-width: 1450px) {
  .us-text-content {
    margin: auto 10px;
  }
}

@media (max-width: 1280px) {
  .us-activities-carousel {
    width: 90%;
  }

  .us-card-wrapper {
    flex-direction: column;
    border-radius: 32px;
    gap: 0;
    height: 100%;

    .us-image-render {
      width: 100%;
      border-radius: 20px 20px 0 0;
    }

    .us-text-content {
      padding: 15px;
    }
  }

  .us-text-content-container {
    height: 100%
  }

  .carousel-container {
    overflow: hidden;
    position: relative;
    margin: 20px auto 10px auto;
    width: 60%;
  }

  .us-roles-container {
    .us-card-container {
      .us-card-container-row {
        .us-title {
          width: 80%;
        }

        .us-description {
          width: 90%;
        }
      }
    }
  }

  .project-container {
    .border-extension-blue {
      height: 600px;
    }

    .project-image {
      height: 60%;
    }
  }

  .us-roles-container {
    .us-card-container {

      .bottom-left-circle {
        bottom: 0;
      }
    }
  }

  .why-us {
    padding-top: 0;

    .why-us-card-container {
      padding: 0 25px 30px;
    }

    .why-us-card-container-row {
      .why-us-card {
        width: 90%;
      }
    }
  }

  .detailed-services-container {
    .business-unit-title {
      .featured-business-units-title {
        font-size: 103px;
        width: 85%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar .navbar-menu-image {
    background-size: cover;
    background-position-x: -8vh;
  }

  .about-us {
    .about-us-container {
      padding: var(--default-margin) 30px;
    }
  }

  .solutions-section {
    .solutions-card-rows {
      gap: 30px;
      padding-top: 30px;
    }
  }

  .us-roles-container {
    .us-card-container {
      min-height: 110vh;

      .top-right-grey-square, .top-right-grey-square-2 {
        display: none;
      }
    }
  }

  .key-words-pill-container {
    flex-wrap: wrap;
  }


  .detailed-services-container {
    .detailed-services-presentation-section, .detailed-cv-services-presentation-section, .detailed-cryptography-services-presentation-section {
      padding-top: 0;
    }

    .service-description-container {
      gap: 15px;
      flex-direction: column;
      height: fit-content;
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom-right-radius: 50px;
    }

    .service-detailed-description {
      width: 100%;
    }
  }

  .other-solution-title-wrapper {
    padding-bottom: 30px;
  }

  .solutions-card {
    .solutions-button-container {
      padding-top: 10px;
    }
  }

  .background-other-solutions-section {
    height: fit-content;
    padding: 40px 0;
  }

  .home-section.presentation {
    height: 70vh;
  }

  .solutions-section {
    .solutions-labs-ship-container {
      width: 30%;
      margin-left: auto;
    }
  }

  .us-roles-container {
    .us-card-container {
      .us-card-container-row {
        .us-title {
          width: 90%;
        }

        .us-description {
          width: 90%;
        }
      }
    }
  }

  .home-section.hero {
    .hero-container {
      top: 60%;
    }
  }

  .home-presentation-why-us-container {
    background: linear-gradient(215deg, #F23F62 0.5%, #953EA2 16%, #533DD0 60%, #293DED 100%);
  }

  .project-image-overlay {
    display: none;
  }

  .project-description-card {
    width: 100%;
    padding-top: 0;
  }

  .other-solutions-section {
    padding: 90px 75px 100px;
  }
}

@media (max-width: 1200px) {
  .solutions-section {
    .solutions-introduction {
      .solutions-card-rows {
        gap: 20px;
      }

      .solutions-second-row {
        flex-wrap: wrap
      }
    }
  }
}

@media (max-width: 990px) {
  .dream-team {
    flex-direction: column;
    min-height: 100vh;

    .dream-team-contact {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-bottom: 80px;
      padding-top: 30px;
    }
  }

  .project-container {

    .project-card-and-image-wrapper {
      width: 350px;
    }

    .border-extension-blue {
      height: 500px;
    }

    .project-info-section-container {
      flex-direction: column;

      .project-info-section {
        flex-direction: column;
        width: 100%;
        padding-left: 0;
      }

      .project-details {
        padding: 0 var(--default-margin);

        .project-history {
          .history-details {
            width: 100%;
          }
        }
      }
    }

    .project-key-points {
      padding: 10% var(--default-margin) 0 var(--default-margin);
    }
  }

  .request-to-join-us-container-wrapper {
    padding: var(--navbar-height) 75px;
  }
}

@media (max-width: 768px) {
  :root {
    --default-margin: 20px;
    --footer-height: 140px;
  }

  .featured-projects-section {
    padding: 40px var(--default-margin) 40px;
  }

  .background-other-solutions-section {
    background: #e6e5ee;
    border-top-left-radius: 50px;
  }

  .home-section.presentation {
    height: 60vh;
  }

  .navbar-menu-container {
    flex-direction: column;
  }

  .navbar .navbar-menu-image {
    background-size: cover;
    background-position: 0 -7vh;
  }

  .project-container {
    .border-extension-blue {
      height: 350px;
    }

    .project-info-section-container {
      border-top-left-radius: 150px;
      border-top-right-radius: 150px;
    }
  }

  .request-to-join-us-section {
    background: var(--blue);
  }

  .request-to-join-us-container-wrapper {
    padding: var(--navbar-height) var(--default-margin);
  }

  .navbar {
    width: 100VW;

    .navbar-logo-link {
      padding-left: 35px;
    }

    .navbar-options {
      padding-right: 35px;
      justify-content: flex-end;
    }
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
    font-weight: 900;
    line-height: 40px;
  }

  h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
  }

  h4 {
    font-size: 25px;
    font-weight: bold;
    line-height: 50px;
  }

  p {
    font-size: 14px;
  }

  .scroll-down-button-container {
    display: none;
  }

  .home-section.presentation {
    .presentation-container {
      padding: 0 var(--default-margin);
    }

    .presentation-button {
      justify-content: center;
    }
  }

  .us-roles-container {
    .us-card-container {
      min-height: 70vh;

      .bottom-left-circle {
        bottom: 30vh;
      }
    }
  }

  .solutions-section {
    .solutions-introduction {
      .solutions-card-rows {
        gap: 20px;
      }

      .solutions-introduction-container {
        padding: 200px var(--default-margin) 0 var(--default-margin);
        margin: 0;
        width: calc(100% - 40px);
      }

      .solutions-second-row {
        flex-direction: column;
      }
    }
  }

  .detailed-services-container {
    .business-unit-title {
      .featured-business-units-title {
        font-size: 70px;
        -webkit-text-stroke: 1px white;
        line-height: 70px;
      }
    }
  }

  .card-solution-container {
    flex-direction: column;
    width: calc(100% - 40px);
  }

  .text-input-container {
    flex-direction: column;
  }

  .contact-us {
    background-image: url("../media/backgrounds/background-contact-mobile.svg");

    .contact-us-form {
      padding: var(--default-margin);
    }
  }

  .big-text-input-container {
    .form-big-text-input {
      width: calc(100% - 40px);
    }
  }

  .footer {
    flex-direction: column;
    align-items: center;

    .company-name-column {
      display: none;
    }

    .social-media-column {
      margin: auto;

      .icons {
        justify-content: center;
      }

      .copyright-text {
        font-size: 10px;
        padding: 0 7%;

        p {
          margin-bottom: 0
        }
      }
    }
  }

  .project-card-and-image-wrapper {
    width: 90%;
  }

  .why-us {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 80px;

    .why-us-card-container-row {
      flex-direction: column;
      gap: 16px;
    }

    .why-us-card-container {
      padding: 0;

      &:first-child {
        .why-us-title {
          display: block;
        }
      }

      .why-us-title {
        display: none;
      }
    }
  }

  .why-us-card {
    width: 85%;

    .card {
      height: auto;
      flex-direction: column;
      width: calc(100% - 64px);

      .text-column {
        .column-header {
          text-align: center;
          font-size: 20px;
          line-height: 30px;
        }

        .column-description {
          display: block;
          padding-top: 10px;
          margin: 14px 0 0;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }

  .us-activities-carousel {
    width: 100%;
  }

  .about-us {
    .about-us-container {
      .about-us-img-container {
        border-radius: 0;
        position: relative;
        padding: 0 2px 0 0;
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
      }

      .left-shape {
        display: none;
      }

      .right-shape {
        display: none;
      }

      .about-us-info {
        width: 100%;
        bottom: -15vh;
        justify-content: space-evenly;

        a {
          align-self: center;
          position: relative;
        }
      }
    }
  }

  .words-vertical-rotator {
    height: auto;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;

    .static-text {
      font-size: 24px;
    }

    .dynamic-text {
      font-size: 34px;
      line-height: 50px;
      height: 50px;

      .dynamic-text-item {
        &.long-list {
          animation: moveLongListMobile 15s infinite 1.5s;
        }
      }
    }
  }

  @keyframes moveLongListMobile {
    0% {
      top: 0;
    }
    10% {
      top: -50px;
    }
    20% {
      top: -100px;
    }
    30% {
      top: -150px;
    }
    40% {
      top: -200px;
    }
    50% {
      top: -250px;
    }
    60% {
      top: -300px;
    }
    70% {
      top: -350px;
    }
    80% {
      top: -400px;
    }
    90% {
      top: -450px;
    }
  }

  .partners {
    height: 70vh;

    .icons-row {
      height: 120px;
      width: 120px;
    }

    .icons-row-four-circles {
      height: 120px;
      width: 120px;
    }

    .partners-title {
      max-width: 90%;
      margin-right: 10px;
      margin-left: 10px;
      align-self: auto;
    }

    .partners-logos {
      margin-right: 10px;
      margin-left: 10px;
      max-width: 80%;
      align-self: auto;
      padding: 40px;
    }
  }

  .solutions-section {
    .solutions-introduction {
      background: url("../media/backgrounds/background-solutions-mobile.svg") no-repeat;
      background-size: cover;
    }

    .solutions-labs {
      background: url("../media/backgrounds/background-labs-mobile.png") no-repeat;
      background-size: cover;
      height: 100vh;
      flex-direction: column-reverse;
    }

    .solutions-labs-content-container {
      width: auto;
      height: auto;
      margin: 0 var(--default-margin) auto var(--default-margin);
    }

    .solutions-labs-ship-container {
      width: 60%;
      margin-left: auto;
      height: 50%;
    }

    .solutions-our-process {
      background: url("../media/backgrounds/background-process-mobile.png") no-repeat;
      background-size: cover;
    }
  }


  .not-found {
    .not-found-container {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .img-column {
      width: 100%;
      max-width: 400px;
    }

    .error-column {
      width: 100%;
      align-items: center;
    }

    .error-description {
      text-align: center;
    }

    .error-button-container {
      padding-top: 0;
    }
  }

  .request-to-join-us-container-wrapper {
    .request-to-join-us-container {
      .request-to-join-us-form {
        padding: var(--default-margin);
      }
    }
  }

  .other-solutions-section {
    padding: 90px var(--default-margin) 100px;
  }

  .us-roles-container {
    .us-card-container {
      background-color: var(--pink);
      background-image: none;
      height: fit-content;

      .bottom-left-circle {
        display: none;
      }

      .bottom-left-small-blue-square {
        display: none;
      }

      .top-transparent-circle {
        display: none;
      }

      .top-blue-semicircle {
        display: none;
      }

      .top-right-grey-square {
        display: none;
      }

      .top-right-grey-square-2 {
        display: none;
      }


      .us-card-container-row {
        .us-info {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  .carousel-container {
    margin: 0;
    width: 100%;
    border-radius: 20px;
  }

  .us-card-wrapper {
    flex-direction: column;
    border-radius: 32px;
    gap: 0;
    height: 100%;

    .us-image-render {
      width: 100%;
    }
  }

  .us-text-content {
    padding: 20px;
  }

  .about-us {
    .about-us-container {
      padding: 0;
      margin: 0;
    }
  }

  .home-section {
    &.hero {
      background: black url("../media/backgrounds/background-hero-mobile-videoframe-0.webp") no-repeat center center;
      background-size: cover;
    }
  }
}

@media (max-height: 700px) {
  .home-section.hero {
    .hero-container {
      flex-direction: row;
    }
  }
}

@media (max-width: 550px) {
  .project-container {
    .project-card-and-image-wrapper {
      width: 406px;
    }
  }

  .detailed-services-container {
    .business-unit-title {
      .featured-business-units-title {
        font-size: 60px;
      }
    }
  }
}

@media (max-width: 450px) {
  .detailed-services-container {
    .business-unit-title {
      .featured-business-units-title {
        font-size: 50px;
      }
    }
  }

  .project-container {
    .border-extension-blue {
      height: 250px;
    }

    .project-card-and-image-wrapper {
      width: 100%;
    }
  }
  .partners {
    height: 70vh;

    .icons-row {
      height: 70px;
      width: 70px;
    }

    .icons-row-four-circles {
      height: 70px;
      width: 70px;
    }

    .partners-title {
      max-width: 85%;
      margin: 0;
      align-self: auto;
      padding: 30px;
    }

    .partners-logos {
      margin: 0;
      max-width: 90%;
      align-self: auto;
      padding: 10px;
    }
  }

  .words-vertical-rotator {
    top: -53vh;
    align-items: center;

    .dynamic-text {
      text-align: center;
    }
  }

  .about-us {
    .about-us-container {
      .about-us-info {
        width: 100%;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 344px) {
  .partners {
    height: 70vh;

    .icons-row {
      height: 30px;
      width: 30px;
    }

    .icons-row-four-circles {
      height: 30px;
      width: 30px;
    }
  }
}
